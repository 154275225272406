import React from "react";

import { OGTags, SEOTags } from "./Layout.interfaces";

const OGMap: { [key in keyof OGTags]: (value: string) => React.ReactNode } = {
  title: (title) => (
    <meta key={"key-og-title"} property="og:title" content={title} />
  ),
  description: (description) => (
    <meta
      key={"key-og-description"}
      property="og:description"
      content={description}
    />
  ),
  image: (imageUrl) => [
    <meta key={"key-og-image"} property="og:image" content={imageUrl} />,
    <meta
      key={"key-og-image-width"}
      property="og:image:width"
      content="1200"
    />,
    <meta
      key={"key-og-image-height"}
      property="og:image:height"
      content="630"
    />,
  ],
  type: (type) => (
    <meta key={"key-og-type"} property="og:type" content={type} />
  ),
  url: (url) => <meta key={"key-og-url"} property="og:url" content={url} />,
  siteName: (siteName) => (
    <meta key={"key-og-siteName"} property="og:site_name" content={siteName} />
  ),
};
export const renderOGTags = (ogTags: OGTags): React.ReactNode[] => {
  return Object.entries(ogTags || {}).map(([key, value]) => OGMap[key](value));
};

const SEOMap: {
  [key in keyof SEOTags]: (value: string | boolean) => React.ReactNode;
} = {
  description: (description) => (
    <meta
      key={"key-meta-description"}
      property="description"
      content={description as string}
    />
  ),
  noFollow: () => (
    <meta key={"key-meta-noFollow"} name="robots" content="nofollow" />
  ),
  noIndex: () => (
    <meta key={"key-meta-robots"} name="robots" content="noindex" />
  ),
};
export const renderSEOTags = (metaTags: SEOTags): React.ReactNode[] => {
  return Object.entries(metaTags || {}).map(([key, value]) =>
    SEOMap[key](value)
  );
};

export const renderStaticTags = (): React.ReactNode[] => [
  <link
    key={"key-link-apple-touch-icon"}
    rel="apple-touch-icon"
    sizes="180x180"
    href="/assetsV2/logos/apple-touch-icon.png"
  />,
  <link
    key={"key-link-favicon32"}
    rel="icon"
    type="image/png"
    sizes="32x32"
    href="/assetsV2/favicon-32x32.png"
  />,
  <link
    key={"key-link-favicon16"}
    rel="icon"
    type="image/png"
    sizes="16x16"
    href="/assetsV2/favicon-16x16.png"
  />,
  <link
    key={"key-link-favicon"}
    rel="shortcut icon"
    href="/assetsV2/favicon.png"
  />,
  <link
    key={"key-link-manifest"}
    rel="manifest"
    href="/assetsV2/site.webmanifest"
  />,
  <link
    key={"key-link-safari-pinned-tab"}
    href="/assetsV2/safari-pinned-tab.svg"
    color="#000000"
  />,
  <meta
    key={"meta-meta-safari-msapplication-tile"}
    name="msapplication-TileColor"
    content="#00a300"
  />,
  <meta
    key={"meta-meta-safari-msapplication-config"}
    name="msapplication-config"
    content="/assetsV2/browserconfig.xml"
  />,
  <meta key={"meta-meta-theme-color"} name="theme-color" content="#ffffff" />,
];
