import { getSessionId } from "../../util/uuid";
import { PublicAPIFetcher } from "../fetcher";

interface TrackFrontendInfoProps {
  event: string;
  version: string;
  preOrderId: string;
}

export const trackFrontendInfo = async (props: TrackFrontendInfoProps) => {
  try {
    const { event, version, preOrderId } = props;
    return PublicAPIFetcher<any>(
      `/info/tracking/${encodeURIComponent(event)}/${encodeURIComponent(
        version
      )}/${encodeURIComponent(preOrderId)}`,
      {
        method: "POST",
      }
    );
  } catch (err) {}
};

interface TrackEventInfoProps {
  event: string;
  frontendVersion: string;
  preOrderId?: string;
}

export const trackEventInfo = async (props: TrackEventInfoProps) => {
  try {
    const { event, preOrderId, frontendVersion } = props;
    const version = process.env.NEXT_PUBLIC_APP_VERSION ?? 0;
    let sessionId = sessionStorage.getItem("sessionId");
    if (!sessionId) {
      sessionId = getSessionId();
      sessionStorage.setItem("sessionId", sessionId);
    }
    return PublicAPIFetcher<void>("/info/track", {
      method: "POST",
      data: {
        event,
        sessionId,
        frontendVersion,
        version: String(version),
        preOrderId,
      },
    });
  } catch (err) {}
};
