export const stringToSHA256 = async (source: string) => {
  try {
    const textAsBuffer = new TextEncoder().encode(source);
    const hashBuffer = await window.crypto.subtle.digest(
      "SHA-256",
      textAsBuffer
    );
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((item) => item.toString(16).padStart(2, "0")).join("");
  } catch (err) {
    return "";
  }
};
