import { COOKIE_PREFIX_KEY } from "./useABTesting";
import { CMSVariant, ExperimentCookie } from "./useABTesting.interfaces";

export const getCookieNameForExperiment = (experimentName: string) => {
  return COOKIE_PREFIX_KEY + experimentName;
};
export const getCookieByExperimentName = (
  experimentName: string
): ExperimentCookie | null => {
  try {
    const cookieKey = getCookieNameForExperiment(experimentName);
    const cookie = document.cookie
      .split(";")
      .map((keyValue) => {
        return keyValue.trim();
      })
      .find((cookie) => {
        return cookie.startsWith(cookieKey);
      });
    if (cookie) {
      const parsedCookie = JSON.parse(decodeURIComponent(cookie.split("=")[1]));
      return !!parsedCookie ? parsedCookie : null;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const setExperimentCookie = (
  experimentName: string,
  cookieValue: ExperimentCookie,
  cookieExpiresInDays: number
) => {
  const cookieKey = getCookieNameForExperiment(experimentName);
  const cookieKeyValue =
    cookieKey + "=" + encodeURIComponent(JSON.stringify(cookieValue));
  const cookieExpireDate =
    "expires=" +
    new Date(
      Date.now() + cookieExpiresInDays * 24 * 60 * 60 * 1000
    ).toUTCString();
  const cookiePath = "path=/";
  document.cookie = [cookieKeyValue, cookieExpireDate, cookiePath].join("; ");
};

export const getVariantAndDraw = (
  variants: CMSVariant[],
  drawNumber?: number
) => {
  const totalWeight = variants
    .map(({ weight }) => weight)
    .reduce((a, b) => {
      return a + b;
    }, 0);
  const random = drawNumber ?? Math.floor(Math.random() * totalWeight + 1);
  let cumulative = 0;
  const variant = variants.find((variant) => {
    cumulative += variant.weight;
    return random <= cumulative;
  });
  return { variant, drawNumber: random };
};
