import axios from "axios";

import {
  APIFetcher,
  APIFetcherWithAxiosResponse,
  GenerateAPIFetcher,
} from "../fetcher.interfaces";

const PublicAPIFetcherGenerator: GenerateAPIFetcher =
  (axiosWrapper) =>
  async <T>(url, options) => {
    const response = await axios<T>(
      `${process.env.NEXT_PUBLIC_API_BASE}${url}`,
      options
    );
    return axiosWrapper ? response : response.data;

    /*
     * Improve Error Handling with Custom Errors ?
     * */
  };

export const PublicAPIFetcher = PublicAPIFetcherGenerator(false) as APIFetcher;
export const PublicAPIFetcherWithAxiosWrapper = PublicAPIFetcherGenerator(
  true
) as APIFetcherWithAxiosResponse;
