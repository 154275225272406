import { LibTheme, ThemeProvider } from "@stadtsalat/component-lib";
import Head from "next/head";
import { FC, PropsWithChildren } from "react";
import { RecoilRoot } from "recoil";

import ErrorContextProvider from "../../context/ErrorContext";
import I18nWrapper from "../../context/i18nContext/I18nWrapper";
import useABTesting from "../../views/MenuViewPage/components/MenuStateWrapper/useABTesting";
import { LayoutProps } from "./Layout.interfaces";
import { renderOGTags, renderSEOTags, renderStaticTags } from "./util";

const GlobalStateProvider: FC<PropsWithChildren> = ({ children }) => {
  useABTesting();
  return <>{children}</>;
};
const Layout: FC<LayoutProps> = (props) => {
  const { containerClassName, title, seo, og, children, initializeState } =
    props;
  return (
    <ErrorContextProvider>
      <I18nWrapper>
        <RecoilRoot initializeState={initializeState}>
          <ThemeProvider theme={LibTheme}>
            <GlobalStateProvider>
              <div className={containerClassName}>
                <Head>
                  <title>{title}</title>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1"
                  />
                  <meta name="description" content={seo.description} />
                  {seo && renderSEOTags(seo)}
                  {og && renderOGTags(og)}
                  {renderStaticTags()}
                </Head>
                {children}
              </div>
            </GlobalStateProvider>
          </ThemeProvider>
        </RecoilRoot>
      </I18nWrapper>
    </ErrorContextProvider>
  );
};

export default Layout;
