import { uuid4 } from "@sentry/utils";
import { getABExperimentMap } from "@util/abtest";

import {
  EventTypes,
  GA4AddPaymentInfoProps,
  GA4AddShippingInfoProps,
  GAItem,
  GenericEventData,
  GenericTrackingProps,
  IEvent,
  IPurchaseEvent,
  TrackingEventMap,
} from "@/types/tracking";

import { trackEventInfo } from "../api/Info/info.api";
import { trackConversion } from "../api/Tracking";
import { stringToSHA256 } from "./crypto";
import { InternalCartItem, InternalCartItemAddon } from "./order";
import { MenuUserTypeStorageAdapter } from "./storage/util.storage";
import CartItem = Definitions.CartItem;
import OrderStatus = Definitions.OrderStatus;
import HydratedProduct = Definitions.HydratedProduct;

const getFrontendVersion = () => {
  const menuUserType = MenuUserTypeStorageAdapter.get();
  if (menuUserType === "NEW") {
    return 2;
  } else if (menuUserType === "LEGACY") {
    return 1;
  }
  return 0;
};

const getGenericTrackingProps = (genericProps?: GenericTrackingProps) => {
  return Object.fromEntries(
    Object.keys(genericProps ?? {}).map((key) => [key, genericProps[key]])
  );
};

const parseValue = (value: string | number | undefined) => {
  if (!value || value === "") {
    return 0;
  } else if (typeof value === "string") {
    return parseFloat(parseFloat(value).toFixed(2));
  }
  return parseFloat(parseFloat(`${value}`).toFixed(2));
};

let checkoutBeginAlreadyTracked = false;
const EventMap: {
  facebook: TrackingEventMap;
  snapchat: TrackingEventMap;
  tiktok: TrackingEventMap;
} = {
  facebook: {
    [EventTypes.PURCHASE]: "Purchase",
    [EventTypes.PAGE_VIEW]: "PageView",
    [EventTypes.VIEW_CONTENT]: "ViewContent",
    [EventTypes.ADD_CART]: "AddToCart'",
    [EventTypes.SIGN_UP]: "CompleteRegistration",
  },
  snapchat: {
    [EventTypes.PURCHASE]: "PURCHASE",
    [EventTypes.PAGE_VIEW]: "PAGE_VIEW",
    [EventTypes.VIEW_CONTENT]: "VIEW_CONTENT",
    [EventTypes.ADD_CART]: "ADD_CART",
    [EventTypes.SIGN_UP]: "SIGN_UP",
  },
  tiktok: {
    [EventTypes.PURCHASE]: "CompletePayment",
    [EventTypes.PAGE_VIEW]: "PageView", // will be automatically tracked by the tiktok pixel
    [EventTypes.VIEW_CONTENT]: "ViewContent",
    [EventTypes.ADD_CART]: "AddToCart",
    [EventTypes.SIGN_UP]: "CompleteRegistration",
  },
};

const getFbContext = (): { fbp: string; fbc?: string } => {
  // extract fbp and fbc from cookie
  const fbp = document!
    .cookie!.split("; ")
    .find((row) => row.startsWith("_fbp="))!
    .split("=")[1];

  // fbc is not available at all times and is optional
  let fbc;
  try {
    fbc = document!
      .cookie!.split("; ")
      .find((row) => row.startsWith("_fbc="))!
      .split("=")[1];
  } catch (e) {
    // silent
  }

  return { fbp, fbc };
};
const generateTrackingEventFunction = <T>(
  func: (eventData: T & IEvent) => void
) => {
  return (eventData: T) => {
    try {
      if (window.dataLayer) {
        const eventId = uuid4();
        func(Object.assign({}, eventData, { eventId }));
      }
    } catch (err) {}
  };
};
const mapCartItemsToGAItems = (cartItems: CartItem[]): GAItem[] => {
  const products: GAItem[] = [];
  const addons: GAItem[] = [];
  cartItems.forEach((cartItem) => {
    products.push({
      item_id: cartItem.id,
      item_name: cartItem.name,
      quantity: cartItem.count,
      item_category: "product",
      price: Number(cartItem.price.withVat),
    });
    cartItem.addons.forEach((addon) => {
      addons.push({
        item_id: addon.id,
        item_name: addon.name,
        quantity: 1,
        item_category: addon.tags.includes("ingredient.dressing")
          ? "dressing"
          : "addon",
      });
    });
  });
  return [...products, ...addons];
};

export const getItemCategory = (
  product: HydratedProduct | InternalCartItem | InternalCartItemAddon
) => {
  if (product.tags.includes("ingredient.dressing")) {
    return "dressing";
  } else if (
    product.tags.some((tag) =>
      ["product.bowl", "product.side", "produkt.drink"].includes(tag)
    )
  ) {
    return "product";
  }
  return "addon";
};

export const addMouseflowTag = (tag: string, data?: Object) => {
  if (!window._mfq) window._mfq = [];
  window._mfq.push(["tag", tag, data]);
};
export const setMouseFlowVariable = (key: string, value: string) => {
  if (!window._mfq) window._mfq = [];
  window._mfq.push(["setVariable", key, value]);
};

export const trackAddToCartEvent = async (
  cartItems: InternalCartItem[],
  genericProps?: GenericTrackingProps
) => {
  try {
    if (window.dataLayer) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "EUR",
          value: parseValue(
            cartItems
              .map((cartItem) =>
                parseFloat(
                  (
                    parseFloat(cartItem.price.withVat) * cartItem.count || 1
                  ).toFixed(2)
                )
              )
              .reduce((a, b) => a + b, 0)
          ),
          items: [
            ...cartItems.map((cartItem) => ({
              item_id: cartItem.id,
              item_name: cartItem.name,
              quantity: 1,
              item_category: getItemCategory(cartItem),
              price: Number(cartItem.price.withVat),
            })),
            ...cartItems
              .map((item) =>
                item.addons.map((addon) => ({
                  item_id: addon.id,
                  item_name: addon.name,
                  quantity: addon.count,
                  item_category: getItemCategory(addon),
                }))
              )
              .flat(1),
          ],
        },
        ...getGenericTrackingProps({
          ...genericProps,
        }),
        ...getABExperimentMap(),
      });
      window.dataLayer.push({
        event: "AddToCart",
        addons: cartItems
          .map((cartItem) => cartItem.addons)
          .flat(1)
          .map((addon) => addon.id),
        products: cartItems
          .filter((cartItem) => getItemCategory(cartItem) === "product")
          .map((product) => product.id),
        value: cartItems
          .map((cartItem) =>
            parseFloat(parseFloat(cartItem.price.withVat).toFixed(2))
          )
          .reduce((a, b) => a + b, 0),
        price: cartItems
          .filter((cartItem) =>
            cartItems.length > 0 ? cartItem.tags.includes("product.bowl") : true
          )
          .map((cartItem) =>
            parseFloat(parseFloat(cartItem.price.withVat).toFixed(2))
          )
          .reduce((a, b) => a + b, 0),
      });
    }
    trackEventInfo({
      event: "add_to_cart",
      frontendVersion: String(getFrontendVersion()),
      preOrderId: genericProps?.preOrderId,
    }).catch((err) => {});
  } catch (err) {}
};
export const trackRemoveFromCartEvent = async (
  product: HydratedProduct,
  count: number,
  genericProps?: GenericTrackingProps
) => {
  try {
    if (window.dataLayer) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
          currency: "EUR",
          value: parseValue(product.price.withVat),
          items: [
            {
              item_id: product.id,
              item_name: product.name,
              quantity: count,
              price: Number(product.price.withVat),
            },
          ],
        },
        ...getGenericTrackingProps({
          ...genericProps,
        }),
        ...getABExperimentMap(),
      });
    }
  } catch (err) {}
};
export const trackPurchaseEvent = async (
  orderStatus: OrderStatus,
  orderCount: number | string,
  genericProps?: GenericTrackingProps
) => {
  try {
    const eventId = uuid4();
    const orderId = orderStatus.id;
    const value = orderStatus.endPricePayableWithVat;
    const userEmail = orderStatus.deliveryInfo.emailAddress;
    const products = orderStatus.cart.map((product) => product.id);
    const voucherCode = orderStatus.voucherCode;
    const deliveryPrice = orderStatus.deliveryPrice;
    if (window.dataLayer) {
      window.dataLayer.push({
        ecommerce: null,
      });
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: orderId,
          currency: "EUR",
          value: parseValue(value),
          coupon: voucherCode,
          shipping: parseValue(deliveryPrice),
          items: [
            ...orderStatus.cart.map((cartItem) => ({
              item_id: cartItem.id,
              item_name: cartItem.name,
              price: Number(cartItem.price.withVat),
            })),
          ],
        },
        email_sha256: await stringToSHA256(
          orderStatus?.deliveryInfo?.emailAddress ?? ""
        ),
        firstName_sha256: Boolean(orderStatus?.deliveryInfo?.firstName)
          ? await stringToSHA256(orderStatus?.deliveryInfo?.firstName)
          : "",
        lastName_sha256: Boolean(orderStatus?.deliveryInfo?.lastName)
          ? await stringToSHA256(orderStatus?.deliveryInfo?.lastName)
          : "",
        ...(orderStatus.deliveryType === "ONLINE_DELIVERY"
          ? {
              street: orderStatus.deliveryInfo.address?.street,
              city: orderStatus.deliveryInfo.address?.city,
              postal_code: orderStatus.deliveryInfo.address?.zipCode,
              region: orderStatus.deliveryInfo.address?.city,
              country: "DE",
            }
          : {}),
        ...getGenericTrackingProps({
          ...genericProps,
        }),
        ...getABExperimentMap(),
      });
      const eventData: IPurchaseEvent = {
        value,
        userEmail,
        products,
        orderCount,
        orderId,
      };
      window.dataLayer.push({
        event: "Purchase",
        ...eventData,
        eventId,
      });
    }
    // server-side tracking via Conversion API
    const { fbp, fbc } = getFbContext();
    if (fbp) {
      trackConversion({
        pixel_id: process.env.FACEBOOK_ID || "1431930983728580",
        event_name: "Purchase",
        event_time: Math.round(Date.now() / 1000),
        event_id: eventId,
        user_data: {
          em: userEmail || "",
          fbc,
          fbp,
        },
        custom_data: {
          currency: "EUR",
          value: Number(value),
          content_ids: products,
          content_type: "product",
        },
        event_source_url: window.location.href,
        action_source: "website",
      }).catch((err) => {});
    }
    await trackEventInfo({
      event: "purchase",
      frontendVersion: String(getFrontendVersion()),
      preOrderId: genericProps?.preOrderId,
    });
  } catch (err) {}
};

export const trackCartViewEvent = async (
  cartItems: CartItem[],
  cartValue: string | number,
  genericProps?: GenericTrackingProps
) => {
  try {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "view_cart",
        ecommerce: {
          currency: "EUR",
          value: parseValue(cartValue),
          items: mapCartItemsToGAItems(cartItems),
        },
        ...getGenericTrackingProps({
          ...genericProps,
        }),
        ...getABExperimentMap(),
      });
    }
  } catch (err) {}
};

export const trackCheckoutBeginEvent = async (
  cartItems: CartItem[] = [],
  cartValue: string | number = 0,
  genericProps?: GenericTrackingProps
) => {
  if (window.dataLayer) {
    if (checkoutBeginAlreadyTracked) return;
    checkoutBeginAlreadyTracked = true;
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: "EUR",
        value: parseValue(cartValue),
        items: mapCartItemsToGAItems(cartItems),
      },
      ...getGenericTrackingProps({
        ...genericProps,
      }),
      ...getABExperimentMap(),
    });
  }
  trackEventInfo({
    event: "begin_checkout",
    frontendVersion: String(getFrontendVersion()),
    preOrderId: genericProps?.preOrderId,
  }).catch((err) => {});
};

export const trackEvent = ({ event, data, genericProps }: GenericEventData) => {
  try {
    const eventId = uuid4();
    if (window.dataLayer) {
      window.dataLayer.push({
        event,
        ...data,
        eventId,
        ...getGenericTrackingProps({
          ...genericProps,
        }),
        ...getABExperimentMap(),
      });
    }
  } catch (err) {}
};

export const trackAddShippingInfoEvent = async (
  props: GA4AddShippingInfoProps,
  genericProps?: GenericTrackingProps
) => {
  try {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
          currency: "EUR",
          value: parseValue(props.value),
          items: mapCartItemsToGAItems(props.cart),
          shipping_tier: props.shipping_tier,
        },
        ...getGenericTrackingProps({
          ...genericProps,
        }),
        ...getABExperimentMap(),
      });
    }
  } catch (err) {}
};
export const trackAddPaymentInfoEvent = async (
  props: GA4AddPaymentInfoProps,
  genericProps?: GenericTrackingProps
) => {
  try {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
          currency: "EUR",
          value: parseValue(props.value),
          items: mapCartItemsToGAItems(props.cart),
          payment_type: props.payment_type,
        },
        ...getGenericTrackingProps({
          ...genericProps,
        }),
        ...getABExperimentMap(),
      });
    }
  } catch (err) {}
};

export const trackLoginEvent = async (genericProps?: GenericTrackingProps) => {
  try {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "login",
        method: "modal",
        ...getGenericTrackingProps({
          ...genericProps,
        }),
        ...getABExperimentMap(),
      });
    }
  } catch (err) {}
};
export const trackSignUpEvent = async (genericProps?: GenericTrackingProps) => {
  try {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "sign_up",
        method: "modal",
        ...getGenericTrackingProps({
          ...genericProps,
        }),
        ...getABExperimentMap(),
      });
    }
  } catch (err) {}
};

export const trackViewItem = (
  product: HydratedProduct,
  genericProps?: GenericTrackingProps
) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "EUR",
        value: parseValue(product.price.withVat),
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            price: parseValue(product.price.withVat),
          },
        ],
      },
      ...getGenericTrackingProps({
        ...genericProps,
      }),
      ...getABExperimentMap(),
    });
  }
};

export const trackGoToCheckoutInCart = async () => {
  try {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "click",
        target: "start_checkout_upsell_dialog",
      });
    }
  } catch (err) {}
};

export const trackGoToCheckoutInUpsellDialog = async () => {
  try {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "click",
        target: "start_checkout_cart",
      });
    }
  } catch (err) {}
};
