import { captureException } from "@sentry/nextjs";

import { deleteCookie, getCookie, setCookie } from "../storage.util";

export interface StorageAdapter<T> {
  get: () => T | null;
  set: (item: T) => void;
  delete: () => void;
}

const deserialize = <T>(serializedItem: string) => {
  const deserialized = JSON.parse(serializedItem);
  if (typeof deserialized === "string") return deserialize(deserialized);
  return deserialized as T;
};
const regex = /["\\]+/g;

const isLocalStorageSupported = () => {
  try {
    if (typeof window === "undefined") return true;
    const testKey = "supported.test";
    const testValue = localStorage.getItem(testKey);
    if (testValue) {
      return true;
    } else {
      localStorage.setItem(testKey, testKey);
      return true;
    }
  } catch (e) {
    return false;
  }
};

const localStorageSupported = isLocalStorageSupported();
const getStorage: (type: "storage" | "session") => {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
} = (type: "storage" | "session") => {
  if (typeof window === "undefined") {
    return {
      getItem: () => null,
      setItem: () => {},
      removeItem: () => {},
    };
  } else {
    const storage = type === "storage" ? localStorage : sessionStorage;
    return {
      getItem: (key) => {
        let item = localStorageSupported
          ? storage.getItem(key)
          : getCookie(key);

        if (!item && localStorageSupported) {
          item = getCookie(key);
        }
        return !!item ? item : null;
      },
      setItem: (key, value) => {
        localStorageSupported
          ? storage.setItem(key, value)
          : setCookie(key, value);
      },
      removeItem: (key) => {
        localStorageSupported ? storage.removeItem(key) : deleteCookie(key);
      },
    };
  }
};

const logCookieUsageToSentry = () => {
  if (!localStorageSupported) {
    const Storage = getStorage("storage");
    const cookieUsedKey = "log.cookieUsed";
    const cookie = Storage.getItem(cookieUsedKey);
    if (!cookie) {
      Storage.setItem(cookieUsedKey, "true");
      captureException("Cookie used");
    }
  }
};

logCookieUsageToSentry();
export const CookieStorageAdapterGenerator = <T>(
  key: string | null,
  ttl: number,
  optionalDefault?: T,
  deserializer?: (input: string) => T,
  serializer?: (input: T) => string
): StorageAdapter<T> => {
  if (typeof window !== "undefined") {
    return {
      get: () => {
        if (!key) return null;
        const item = getCookie(key);
        if (item) {
          return (deserializer ? deserializer(item) : item) as T;
        }
        return optionalDefault as T;
      },
      set: (item: T) => {
        if (key) {
          const serializedItem = serializer
            ? serializer(item)
            : (item as string);
          setCookie(key, serializedItem, ttl);
        }
      },
      delete: () => {
        if (key) {
          deleteCookie(key);
        }
      },
    };
  } else {
    return {
      get: () => optionalDefault,
      set: () => {},
      delete: () => {},
    };
  }
};
const StorageAdapterGenerator =
  (type: "storage" | "session") =>
  <T>(
    key: string,
    optionalDefault?: T,
    needsSerialization: boolean = false
  ): StorageAdapter<T | string> => {
    const Storage = getStorage(type);
    if (typeof window !== "undefined") {
      return {
        get: (): T => {
          const serializedItem = Storage.getItem(key);

          if (serializedItem) {
            if (needsSerialization !== undefined ? needsSerialization : true) {
              return deserialize(serializedItem) as T;
            }
            const cleanedString = serializedItem.replaceAll(regex, "");
            return cleanedString as T;
          }
          return optionalDefault as T;
        },
        set: (item) => {
          // double-stringified due to the current frontend
          if (item !== undefined && item !== null) {
            const serializedItem = JSON.stringify(JSON.stringify(item));
            Storage.setItem(key, serializedItem);
          } else {
            Storage.removeItem(key);
          }
        },
        delete: () => {
          Storage.removeItem(key);
        },
      };
    }
    return {
      get: () => optionalDefault,
      set: () => {},
      delete: () => {},
    };
  };

export const SessionStorageAdapterGenerator =
  StorageAdapterGenerator("session");
export default StorageAdapterGenerator("storage");
