import { CheckoutPathClearName } from "../views/CheckoutPageView/CheckoutPageView.interfaces";

export const RouteMap = {
  MENU: (deliveryGroupId?: string) => encodeURI(`/${deliveryGroupId ?? ""}`),
  PAYMENT_ERROR: (urlParams?: {
    errorCode?: string;
    errorReason?: string;
    orderHash?: string;
  }) =>
    encodeURI(
      `/order/payment-error?${
        urlParams?.errorCode ? `&errorCode=${urlParams.errorCode}` : ""
      }${
        urlParams?.errorReason ? `&errorReason=${urlParams.errorReason}` : ""
      }${urlParams?.orderHash ? `&order=${urlParams.orderHash}` : ""}`
    ),
  ORDER_SUCCESS: (orderHash: string) =>
    encodeURI(`/order/success?order=${orderHash}`),
  ORDER_PROCESSING: (query: { [index: string]: string } = {}) =>
    encodeURI(
      "/order/processing" +
        `${
          Object.keys(query).length > 0
            ? `?${Object.keys(query)
                .map((key) => `${key}=${query[key]}`)
                .join("&")}`
            : ""
        }`
    ),
  ORDER_NOT_FOUND: () => encodeURI("/order/not-found"),
  PRODUCT_UNAVAILABLE: () => encodeURI("/order/product-unavailable"),
  CHECKOUT: (path?: CheckoutPathClearName) =>
    encodeURI(`/order/checkout${path ? `?step=${path}` : ""}`),
  PRIVACY: () => encodeURI("/datenschutz"),
  AGB: () => encodeURI("/agb"),
  CONTACT: () => encodeURI("/contact"),
};
