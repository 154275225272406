import { captureException } from "@sentry/nextjs";
import { createContext, ErrorInfo, FC, useCallback } from "react";

import {
  ErrorHandler,
  RequestErrorHandler,
  ResponseWithException,
} from "./Error.interfaces";
import ErrorBoundary from "./ErrorBoundary";

export enum ErrorLevel {
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export interface ErrorMessage {
  errorLevel: ErrorLevel;
  errorMessage: string;
  id: string;
}
interface IErrorContext {
  onCriticalError: ErrorHandler;
  wrapAPIRequest: RequestErrorHandler;
  reportError: (error: any) => void;
}

const initialState: IErrorContext = {
  //onCritialError will be called when the Error Boundary did catch an Error
  onCriticalError: (err: Error, errorInfo: ErrorInfo) => {},
  // Wrapper to handle API Response
  wrapAPIRequest: undefined,
  reportError: () => {},
};

const getErrorResponse = (
  message: string = "",
  state: any,
  code?: string | number
): ResponseWithException => {
  return {
    error: true,
    message: message,
    errorState: {
      code,
      state,
    },
  };
};

export const ErrorContext = createContext(initialState);
ErrorContext.displayName = "ErrorContext";

const ErrorContextProvider: FC<{ children: React.ReactNode }> = (props) => {
  const onCriticalError = useCallback((error: Error, errorInfo: ErrorInfo) => {
    reportError(error, { tags: errorInfo });
  }, []);

  const wrapAPIRequest: RequestErrorHandler = async (
    APIRequest,
    ignoreResponseCodes = []
  ) => {
    try {
      return { response: await APIRequest };
    } catch (err) {
      if (err.status) {
        if (!ignoreResponseCodes.includes(err.status)) {
          reportError(err);
        }
        return getErrorResponse(
          err.response?.body?.message,
          err.state,
          err.status
        );
      }
    }
  };
  const reportError = useCallback((error: Error, scope?: any) => {
    captureException(error, scope);
  }, []);
  return (
    <ErrorBoundary onCatchedError={onCriticalError}>
      <ErrorContext.Provider
        value={{
          onCriticalError,
          wrapAPIRequest,
          reportError,
        }}
      >
        {props.children}
      </ErrorContext.Provider>
    </ErrorBoundary>
  );
};

export default ErrorContextProvider;
