import { useRouter } from "next/router";
import React from "react";

const DEFAULT_LOCALE = "de";
export const Context = React.createContext({ locale: DEFAULT_LOCALE });

interface WrapperProps {
  children: React.ReactNode;
}

const I18nWrapper = (props: WrapperProps) => {
  const { defaultLocale, locale = defaultLocale || DEFAULT_LOCALE } =
    useRouter();

  return (
    <Context.Provider value={{ locale }}>{props.children}</Context.Provider>
  );
};

export default I18nWrapper;
