import { CheckoutPaths } from "../../views/CheckoutPageView/CheckoutPageView.interfaces";
import StorageAdapterGenerator from "./StorageAdapterGenerator";

export const MIGRATION_FLAG_KEY = "migrationV2";
export const MigrationFlagStorageAdapter = StorageAdapterGenerator<boolean>(
  MIGRATION_FLAG_KEY,
  false,
  true
);

const ACCORDION_TIMING_KEY = "accordionTiming";
export const AccordionTimingStorageAdapter = StorageAdapterGenerator<{
  [key in CheckoutPaths]?: string;
}>(ACCORDION_TIMING_KEY, {}, true);

const GOOGLEPAY_EXPERIMENT_KEY = "experiment.googlepay";
export const GooglePayExperimentStorageAdapter =
  StorageAdapterGenerator<boolean>(GOOGLEPAY_EXPERIMENT_KEY, false, true);

const APPLEPAY_EXPERIMENT_KEY = "experiment.applepay";
export const ApplePayExperimentStorageAdapter =
  StorageAdapterGenerator<boolean>(APPLEPAY_EXPERIMENT_KEY, false, true);

export const MENU_USER_TYPE_KEY = "menuUserType";
export const MenuUserTypeStorageAdapter = StorageAdapterGenerator<
  "NEW" | "LEGACY" | null
>(MENU_USER_TYPE_KEY, null, false);
