import axios, { AxiosResponse } from "axios";

import {
  B2BAuthTokenStorageAdapter,
  UserAuthTokenStorageAdapter,
} from "../../../util/storage/auth.storage";
import { isB2CUser } from "../accountAPI";
import {
  APIFetcher,
  APIFetcherWithAxiosResponse,
  GenerateAPIFetcher,
} from "../fetcher.interfaces";

const GenericAccountAxios = axios.create();
GenericAccountAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    let storageAdapter = isB2CUser()
      ? UserAuthTokenStorageAdapter
      : B2BAuthTokenStorageAdapter;
    if (error.response.status === 401) {
      storageAdapter.delete();
    }
    return Promise.reject(error);
  }
);

const GenericAPIFetcherGenerator: GenerateAPIFetcher =
  (axiosWrapper) =>
  async <T>(url, options) => {
    let storageAdapter = isB2CUser()
      ? UserAuthTokenStorageAdapter
      : B2BAuthTokenStorageAdapter;
    const token = !!options?.token ? options.token : storageAdapter.get();
    const _options = {
      ...options,
      headers: {
        ...(options?.headers
          ? { ...options.headers }
          : {
              Authorization: !!token ? `Bearer ${token}` : null,
            }),
      },
    };
    const response = await GenericAccountAxios<T>(
      `${process.env.NEXT_PUBLIC_API_BASE}${url}`,
      _options
    );
    if (axiosWrapper) {
      return response as AxiosResponse<T>;
    } else {
      return response.data as T;
    }
  };

export const Fetcher = GenericAPIFetcherGenerator(false) as APIFetcher;
export const FetcherWithAxiosWrapper = GenericAPIFetcherGenerator(
  true
) as APIFetcherWithAxiosResponse;
