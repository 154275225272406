import axios, { AxiosResponse } from "axios";

import { UserAuthTokenStorageAdapter } from "../../../util/storage/auth.storage";
import {
  APIFetcher,
  APIFetcherWithAxiosResponse,
  GenerateAPIFetcher,
} from "../fetcher.interfaces";

const B2CAxios = axios.create();
B2CAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      UserAuthTokenStorageAdapter.delete();
    }
    return Promise.reject(error);
  }
);

const AccountAPIFetcherGenerator: GenerateAPIFetcher =
  (axiosWrapper) =>
  async <T>(url, options) => {
    const token = !!options?.token
      ? options.token
      : UserAuthTokenStorageAdapter.get();
    const _options = {
      ...options,
      headers: {
        ...(options?.headers
          ? { ...options.headers }
          : {
              Authorization: `Bearer ${token}`,
            }),
      },
    };
    const response = await B2CAxios<T>(
      `${process.env.NEXT_PUBLIC_API_BASE}${url}`,
      _options
    );
    if (axiosWrapper) {
      return response as AxiosResponse<T>;
    } else {
      return response.data as T;
    }
  };

export const AccountAPIFetcher = AccountAPIFetcherGenerator(
  false
) as APIFetcher;
export const AccountAPIFetcherWithAxiosWrapper = AccountAPIFetcherGenerator(
  true
) as APIFetcherWithAxiosResponse;

export const isB2CUser = (): boolean => {
  const token = UserAuthTokenStorageAdapter.get();
  if (!!token && token !== "null") return true;
  return false;
};
