export const getCookie = (key: string) => {
  if (typeof window === "undefined") return;
  const cookieMap = document.cookie
    .split(";")
    .map((item) => item.trim())
    .reduce((prev, cur) => {
      const [key, value] = cur.split("=");
      return Object.assign({}, prev, { [key]: value });
    }, {});
  return !!cookieMap[key] ? decodeURIComponent(cookieMap[key]) : null;
};
export const setCookie = (key: string, value: string, ttl?: number) => {
  if (typeof window === "undefined") return;
  document.cookie = `${key}=${encodeURIComponent(value)};${
    !!ttl ? `expires=${new Date(Date.now() + ttl).toUTCString()};` : ""
  }path=/`;
};

export const deleteCookie = (key: string) => {
  if (typeof window === "undefined") return;
  document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
};
