import CartItem = Definitions.CartItem;
import HydratedProduct = Definitions.HydratedProduct;
import OrderStatus = Definitions.OrderStatus;

export interface IEvent {
  eventId: string;
}

export interface IPurchaseEvent {
  //total price of the purchase
  value: string;
  //customer email
  userEmail: string;
  //list of purchased product ids
  products: string[];
  //tracking of new / existing customer
  orderCount: number | string;
  // order id
  orderId: string;
}

export enum EventTypes {
  PURCHASE = "PURCHASE",
  PAGE_VIEW = "PAGE_VIEW",
  VIEW_CONTENT = "VIEW_CONTENT",
  ADD_CART = "ADD_CART",
  SIGN_UP = "ADD_CART",
}

export interface ITrackEvent {
  type?: EventTypes;
  userEmail?: string;
  cartItems?: CartItem[];
  pageType?: string;
  totalPrice: string;
}

export interface GenericTrackingProps {
  preOrderId?: string;
}

export interface GenericEventData {
  event: string;
  data: Object;
  genericProps?: GenericTrackingProps;
}

export interface IUseTracking {
  trackPurchaseEvent: (
    orderStatus: OrderStatus,
    orderCount: string | number
  ) => void;
  trackEvent: (event: GenericEventData) => void;
  trackAddToCartEvent: (
    product: HydratedProduct,
    addons: HydratedProduct[],
    count: number
  ) => Promise<void>;
  trackRemoveFromCartEvent: (
    product: HydratedProduct,
    count: number
  ) => Promise<void>;
  trackCartViewEvent: (
    cartItems: CartItem[],
    cartValue: string | number
  ) => Promise<void>;
  trackCheckoutBeginEvent: (
    cartItems: CartItem[],
    cartValue: string | number
  ) => Promise<void>;
}

export type TrackingEventMap = {
  [key in EventTypes]: string;
};

export type GAItem = {
  item_id: string;
  item_name: string;
  quantity: number;
  item_category?: string;
  price?: number;
};

interface GA4BaseProps {
  value: number | string;
  cart: CartItem[];
}

export interface GA4AddShippingInfoProps extends GA4BaseProps {
  shipping_tier?: string;
}

export interface GA4AddPaymentInfoProps extends GA4BaseProps {
  payment_type?: string;
}
