import { PublicAPIFetcher } from "../fetcher";

interface TrackingRequest {
  pixel_id: string;
  event_name: "Purchase" | "AddToCart" | "ViewContent";
  event_id: string;
  event_time: number;
  user_data: {
    em: string;
    fbc?: string;
    fbp: string;
  };
  custom_data: {
    currency: string;
    value: number;
    content_ids: string[];
    content_type: string;
  };
  event_source_url: string;
  action_source: "website";
}

export const trackConversion = async (conversionData: TrackingRequest) => {
  const response = await PublicAPIFetcher<void>(
    "/marketing/analytics/conversion",
    {
      method: "POST",
      data: conversionData,
    }
  );
};
