import { Component, ErrorInfo, ReactNode } from "react";

import { ErrorHandler } from "./Error.interfaces";

interface IErrorBoundaryProps {
  children: ReactNode; // Why ? Should be in default props of Class Component?!
  onCatchedError: ErrorHandler;
}
interface IErrorBoundaryState {
  hasCriticalError: boolean;
}
class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  onError: ErrorHandler;
  constructor(props) {
    super(props);
    this.state = { hasCriticalError: false };
    this.onError = props.onCatchedError;
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasCriticalError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service --> sentry
    this.onError(error, errorInfo);
    //logErrorToMyService(error, errorInfo);
  }
  render() {
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
