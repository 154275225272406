export type ABExperiment = {
  variant: "control" | string;
  drawNumber: number;
  version: number;
};
const abTestingCookiePrefix = "ab_test_";
export const getExperimentCookieKey = (experimentName: string) =>
  `${abTestingCookiePrefix}${experimentName}`;
export const getAllABExperiments: () => Record<string, ABExperiment> = () => {
  return Object.fromEntries(
    document.cookie
      .split(";")
      .map((keyValueString) => keyValueString.trim())
      .filter((keyValueString) =>
        keyValueString.startsWith(abTestingCookiePrefix)
      )
      .map((keyValueString) => {
        try {
          const [key, value] = keyValueString.split("=");
          return [key, JSON.parse(decodeURIComponent(value)) as ABExperiment];
        } catch (err) {
          return null;
        }
      })
      .filter(Boolean)
  );
};
export const getABExperimentMap = (): Record<string, string> => {
  const abExperiments = getAllABExperiments();
  return !!abExperiments
    ? Object.fromEntries(
        Object.keys(abExperiments).map((experimentKey) => [
          experimentKey,
          abExperiments[experimentKey].variant,
        ])
      )
    : {};
};
export const getExperimentVariant = (
  experimentName: string
): { variant: string; drawNumber: number } | null => {
  try {
    const experimentCookies = getAllABExperiments();
    const experimentCookie =
      experimentCookies[getExperimentCookieKey(experimentName)];
    return experimentCookie ? experimentCookie : null;
  } catch (err) {
    return null;
  }
};
