import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";

import {
  getCookieByExperimentName,
  getVariantAndDraw,
  setExperimentCookie,
} from "./util";

export const COOKIE_PREFIX_KEY = "ab_test_";

const useABTesting = () => {
  //const { cmsData } = useCMS();
  const { isReady } = useRouter();
  const setup = useCallback(() => {
    const experiments = [
      {
        experimentName: "ab_cms",
        variants: [
          {
            name: "A",
            weight: 50,
          },
          {
            name: "control",
            weight: 50,
          },
        ],
        version: 2,
        expiresInDays: 30,
      },
      {
        experimentName: "ab_protein_switch",
        variants: [
          {
            name: "PROTEIN_A",
            weight: 50,
          },
          {
            name: "control",
            weight: 50,
          },
        ],
        version: 3,
        expiresInDays: 30,
      },
    ]; //(cmsData?.abExperiments ?? []) as CMSExperiment[];
    experiments.forEach((experiment) => {
      const { experimentName, variants, version, expiresInDays } = experiment;
      const cookie = getCookieByExperimentName(experimentName);
      if (!cookie) {
        const { variant, drawNumber } = getVariantAndDraw(variants);
        const experimentCookie = {
          variant: !!variant ? variant.name : "CATCH",
          drawNumber,
          version,
        };
        setExperimentCookie(experimentName, experimentCookie, expiresInDays);
      } else if (cookie && cookie.version < version) {
        const { variant } = getVariantAndDraw(variants, cookie.drawNumber);
        const experimentCookie = {
          variant: !!variant ? variant.name : "CATCH",
          drawNumber: cookie.drawNumber,
          version,
        };
        setExperimentCookie(experimentName, experimentCookie, expiresInDays);
      }
    });
  }, []); //[cmsData]);
  useEffect(() => {
    if (isReady) {
      setup();
    }
  }, [isReady]);
};

export default useABTesting;
